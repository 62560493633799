import React, {FC, Fragment} from 'react'
import classes from './HeaderTab.module.css';
import {Menu, Transition} from '@headlessui/react'
import Icon from "../../Icons";
import {CheckMark, HKCustomize, HKRing} from "../../Images";
import UseHeaderTab from './useHeaderTab';
import {RootState} from "../../../reducers/RootReducer";
import {connect, ConnectedProps} from "react-redux";
import {setHeaderTabDefault,setDiamondTabIconTrue, setHeaderTabToSetting,setSettingTabIconTrue,setBothTabIconTrue} from '../../../action/HeaderAction';
import {setInitialOption} from '../../../action/RingAction';
import {setIsShowFooter} from '../../../action/FooterAction';
import {HeaderProp, PropsFromScreenManager} from "../../../types";
import {setInitialStartWithSettingData} from '../../../action/StartWithSettingFilterAction';
import {setInitialDiamondOption} from '../../../action/DiamondAction';

const mapStateToProps = (state: RootState) => ({
    header: state.header,
    ring:state.ring,
    diamond:state.diamond,
    footer:state.footer
});

const mapDispatchToProps = {
    setHeaderTabToSetting,
    setInitialOption,
    setIsShowFooter,
    setDiamondTabIconTrue,
    setHeaderTabDefault,
    setSettingTabIconTrue,
    setInitialStartWithSettingData,
    setInitialDiamondOption,
    setBothTabIconTrue
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromHeaderTabRedux = ConnectedProps<typeof connector>;

const HeaderTab: FC<HeaderProp & PropsFromScreenManager> = (props) => {
    const { label, isActive, isDisabled, isIcon, openTab} = props;
    const {handleChangePath} = UseHeaderTab(props);
    if (label === 'SELECT DIAMOND') {
        return (
            <div
                className={`${classes.tab} ${isActive ? classes.active : ''}`}
            >
                {label}
                {isIcon && <img src={CheckMark} className={`${isDisabled ? 'opacity-[0.30]': ''} w-5 h-5`}/>}
            </div>
        )
    } else {
        return (
            <Menu as="div" className={classes.menuMain}>
                <Menu.Button className={`${classes.tab} ${isActive ? classes.active : ''}`} disabled={isDisabled}>
                    {label}
                    {isIcon ? <img src={CheckMark} className={`${isDisabled ? 'opacity-[0.30]': ''} w-5 h-5`}/> : <Icon name={'hk_chevron_down'}/>}
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className={classes.menuItems}>
                        <ul className={classes.menuWrap}>
                            {(openTab === 'diamond' || openTab === 'dyo') &&
                            <Menu.Item>
                                <li
                                    className={classes.dialogDetail}
                                    onClick={() => handleChangePath('setting')}
                                >
                                    <img src={HKRing} className={classes.dialogImg}
                                         alt="Customise your own"/>
                                    <div className={classes.dialogText}>
                                        <h2 className={classes.dialogTitle}>{"Select a Setting"}</h2>
                                        <p className={classes.dialogParagraph}>{"Select a ring setting from our exclusive curation."}</p>
                                    </div>
                                </li>
                            </Menu.Item>
                            }
                            <hr/>
                            {(openTab === 'diamond' || openTab === 'setting') &&
                            <Menu.Item>
                                <li
                                    className={classes.dialogDetail}
                                    onClick={() => handleChangePath('dyo')}
                                >
                                    <img src={HKCustomize} className={classes.dialogImg}
                                         alt="Customise your own"/>
                                    <div className={classes.dialogText}>
                                        <h2 className={classes.dialogTitle}>{"Design Your Own"}</h2>
                                        <p className={classes.dialogParagraph}>{"Design your own engagement ring by customizing your ring’s style, setting, metal and so much more."}</p>
                                    </div>
                                </li>
                            </Menu.Item>
                            }
                        </ul>
                    </Menu.Items>
                </Transition>
            </Menu>
        )
    }
}
export default connector(HeaderTab);
