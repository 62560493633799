import React, {FC, Fragment} from 'react';
import Label from '../Label/Label';
import Button from '../Button';
import usePdpBuilder from './useProductBuilder';
import {RootState} from "../../reducers/RootReducer";
import {connect, ConnectedProps} from "react-redux";
import {
  setBackDiamondDetails,
  setBandTabIndex,
  setInitialOption,
  setExclusionOption,
  setOptionsData,
  setOptionsFail,
  setOptionsLoading,
  setPriceLoading,
  setRemoveRingOption,
  setRingExtraData,
  setRingOptions,
  setRingPrice,
  setRingPriceError,
  setSelectedDiamondData,
  setSelectedDiamondFail,
  setSelectedDiamondLoading,
  setSelectedRingDetails,
  setIsHandLoaded
} from '../../action/RingAction'
import {setFooterToggle, setIsShowFooter, setRedirectEditPage, setStyleID} from '../../action/FooterAction';
import {setAfterRingOptions, setFilter} from '../../action/FilterAction';
import {setDiamondDetailsFail, setDiamondDetailsLoading, setDiamondDetailsSuccess,setBackToSelectDiamondPage} from '../../action/DiamondAction';
import {set3DImageLoading, set3DImageSuccess} from '../../action/CartAction';
import Loader from '../Loader/Loader';
import DiamondSizeSlider from "../DiamondSizeSlider";
import {centerDiamondSizeType, DiamondSizeValueType, PDPBuilderType, PropsFromScreenManager} from "../../types";
import FloatingLabel from "../FloatingLabel/floatingLabel";
import {setHeaderTabDefault, setSettingTabIconTrue} from '../../action/HeaderAction';
import {SetTooltipDataError, setTooltipDataLoading, SetTooltipDataSuccess} from "../../action/TooltipAction";
import classes from './PdpBuilder.module.css';
import {ToLocalStringConvert} from "../../Utils/toLocalStringConvert";
import {DiamondShapeContent, PDPColorImage, weddingButton} from "../../Utils/commonData";
import {setExpandedRow,toggleScrollTop} from '../../action/TableAction';
import Icon from "../Icons";
import {setInitialStartWithSettingData,setResetStartWithSettingFilter} from '../../action/StartWithSettingFilterAction';
import {
    Beadset,
    CarmenCathedral, Channel,
    DeliaTwist, DoubleHalo,
    EmmaSplit, HiddenHalo,
    NellKnifeEdge,
    ParkerClassic, Pave, Plain, Prong, ProngCrown, SingleHalo,
    StellaThreeStone
} from "../Images";

const mapStateToProps = (state: RootState) => ({
    ring: state.ring,
    diamond: state.diamond,
    header :state.header,
    tooltip:state.tooltip,
    stateManager: state.stateManager,
    footer:state.footer,
    settingFilter:state.settingFilter,
    table:state.table,
    filter:state.filter
});

const mapDispatchToProps = {
    setRingOptions,
    setOptionsLoading,
    setOptionsData,
    setOptionsFail,
    setSelectedDiamondLoading,
    setSelectedDiamondData,
    setSelectedDiamondFail,
    setSelectedRingDetails,
    setFooterToggle,
    setFilter,
    setDiamondDetailsLoading,
    setDiamondDetailsFail,
    setDiamondDetailsSuccess,
    setInitialOption,
    setBackDiamondDetails,
    setSettingTabIconTrue,
    setHeaderTabDefault,
    setAfterRingOptions,
    setRemoveRingOption,
    setBandTabIndex,
    SetTooltipDataSuccess,
    setTooltipDataLoading,
    SetTooltipDataError,
    setIsShowFooter,
    setStyleID,
    setRedirectEditPage,
    setPriceLoading,
    setRingPrice,
    setRingPriceError,
    setExpandedRow,
    set3DImageLoading,
    set3DImageSuccess,
    setRingExtraData,
    setInitialStartWithSettingData,
    setResetStartWithSettingFilter,
    setBackToSelectDiamondPage,
    toggleScrollTop,
    setExclusionOption,
    setIsHandLoaded
};
const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRingRedux = ConnectedProps<typeof connector>;

const PdpBuilder: FC<PDPBuilderType & PropsFromScreenManager> = (props) => {
    const {
        ring,
        diamond,
        footer,
        settingFilter,
        setBandTabIndex,
        setBackDiamondDetails,
        setHeaderTabDefault,
        setRemoveRingOption,
        setRingOptions,
        setRedirectEditPage,
        setInitialOption,
        setBackToSelectDiamondPage,
        setIsShowFooter,
        instanceData: { screenManager },
        setInitialStartWithSettingData,
        toggleScrollTop,
        setResetStartWithSettingFilter
    }  = props;

    const {
        productName,
        handleAllOptions,
        styleId,
        handleSelectSetting,
        fieldValidation,
        isEditableRingOptions,
        rpid,
        uuid,
        builder_mode,
        weddingBand,
        ringPrice,
        handleWeddingBand,
        setShowError
    } = usePdpBuilder(props);

    if(ring.isLoading || diamond.isLoading || ring.isSelectedDiamondLoading){
       return <div className={'loading-details'}><Loader position='absolute' /></div>
    }
    if(ring.error){
        return <div>Error</div>
    }

    const pdpImages:{[key:string]:string} = {
        PKR:ParkerClassic,
        EMS: EmmaSplit,
        CCD:CarmenCathedral,
        DLT: DeliaTwist,
        NKE: NellKnifeEdge,
        STS: StellaThreeStone,
        PRG: Prong,
        BDS: Beadset,
        HHL: HiddenHalo,
        SHL: SingleHalo,
        DHL: DoubleHalo,
        PLN: Plain,
        PVE:Pave,
        CNL: Channel,
        CLP:ProngCrown
    }

    const handleMatchingBand = (index: number,Code : string) => {
        setShowError(false);
        isEditableRingOptions.current = false;
        window.postMessage({
          action : index === 0 ? 'ChangeLeftBandColor' : 'ChangeRightBandColor',
          value: Code?.split('_')[1]
        })
        setRingOptions(
          index === 0 ?
            `${ring.options["Wedding_Band"]}_First_Band`:
            `${ring.options["Wedding_Band"]}_Second_Band`,Code?.split('_')[1]
        )
        setRingOptions(
          index === 0 ?
            `${ring.options["Wedding_Band"]}_First_Metal_Band`:
            `${ring.options["Wedding_Band"]}_Second_Metal_Band`,Code
        )
    }
    return (
    <>
        <div className={classes.root}>
            <div className={classes.stickys}>
                <div className={classes.title_wrap}>
                    <h1 className={classes.title}>
                        {productName}
                    </h1>
                  {/*{ring.isPriceLoading ? <div>Loading...</div> : <div className={classes.price}>${ToLocalStringConvert(+(diamond.details.b2c_price ? diamond.details.b2c_price : 0) + +ring.ringPrice)}</div>}*/}

                      <div className={classes.price}>
                          {ring.isPriceLoading || !ringPrice || ringPrice <= 0  ? <small className={classes.smaller}>Loading...</small> : `$${ToLocalStringConvert(+ringPrice)}` }
                      </div>


                </div>
                {ring.ringPrice.ring_tcw || ring.ringPrice.bands_tcw ?
                    <ul className={classes.subtitle}>
                        {ring.ringPrice.ring_tcw ? <li>Minimum Setting Total Weight: {ring.ringPrice.ring_tcw} ct.</li> : null}
                        {ring.options["Wedding_Band"] !== weddingBand[0] && ring.ringPrice.bands_tcw ?
                        <li>{ring.options["Wedding_Band"] === weddingBand[1] ? 'Band' : 'Bands'} Total
                            Weight: {ring.ringPrice.bands_tcw} ct.</li> : null}
                    </ul>:''
                }
            </div>
            <div className={classes.details}>
                {/*{ring.extraData.Description ? <p className={classes.desc}>{ring.extraData.Description}</p> :''}*/}
                {(diamond.details && diamond.details.carat && +diamond.details.carat > 3.00) || (ring.options.Center_Diamond_Size && ring.options.Center_Diamond_Size === "300") ? <p className={classes.desc}>The preview image represents diamonds up to 3 carats. Larger sizes will not be shown to scale.</p> : ''}
                {Object.entries(ring.optionsData).filter(([key,value]) =>!Object.keys(ring.selectedDiamondOptions).includes(key)).map(([key,value],index)=>{
                    if(key === 'Center_Diamond_Size') {
                      const DiamondSizeValue =  (value as DiamondSizeValueType[]).filter(option => !ring.exclusionOption.includes(option.Code));
                        const marks : { [key: number]: string } = DiamondSizeValue.reduce((acc, val, index) => {
                            const step = 100 / (DiamondSizeValue.length); // Calculate the step size
                            const key = step * index;
                            return { ...acc, [key]: val.Name };
                        }, {});
                        const fIndex = (DiamondSizeValue as any[]).map((v) => v.Code).indexOf(ring.options[key]);
                        return (
                            <div className={`${classes.items} hk_${key.toLowerCase()}`} key={index}>
                                <Label text={key.replaceAll('_',' ')} page={'pdpBuilder'}>
                                {/* shapeText={ring.options[key as keyof typeof ring.options]} */}
                                    <div className={classes.wrapper}>
                                        <DiamondSizeSlider
                                            marks={marks}
                                            handleAllOptions={handleAllOptions}
                                            shapeKey={key}
                                            fIndex={fIndex}
                                            allValue={value as centerDiamondSizeType[]}
                                        />
                                    </div>
                                </Label>
                            </div>
                        )
                    }else {
                        return (
                            <Fragment key={index}>{(Array.isArray(value) && value.length > 1) ?
                              <div className={`${classes.items} hk_${key.toLowerCase()} ${key.toLowerCase() === "color" ? `head_color` : '' }`}>
                                {/* shapeText={ring.options[key as keyof typeof ring.options]} */}
                                <Label text={key === "Metal_Color" ? "Metal" : key.replaceAll('_',' ')} page={'pdpBuilder'}>
                                    <div className={classes.wrapper}>
                                        {(value as any[]).filter(option => !ring.exclusionOption.includes(option.Code)).map((v,index)=>{
                                            const isDiamondShape = DiamondShapeContent.find((shape)=>shape.code === v.Code);
                                            const isDisabled = !!Object.keys(ring.selectedDiamondOptions).find(diamond => diamond === key);
                                            return(
                                                <Fragment key={index}>{!(["Jenny Packham"].includes(ring.extraData.Label) && key === "Metal_Color" && v.Code.match("14")) ?<Button
                                                    key={index}
                                                    isDisabled={isDisabled || (key === "Diamond_Type" && !["Generic"].includes(ring.extraData.Label))}
                                                    buttonType={ring.options &&  ["Metal_Color"].includes(key) ? (`${ring.options["Metal"]}_${ring.options["Color"]}` === v.Code ? "active"  : undefined ):(ring.options[key as keyof typeof ring.options] === v.Code ? "active" : undefined)}
                                                    type="button"
                                                    name={key}
                                                    code={v.Code}
                                                    className = {`${classes.button} hk_${key.toLowerCase()}_button`}
                                                    onClick={() => {
                                                        if (ring.options[key] !== v.Code) {
                                                            handleAllOptions(key, v);
                                                        }
                                                    }}
                                                >
                                                    {(v.image_name && !isDiamondShape) && (
                                                        <img
                                                            className={classes.swatchImg}
                                                            src={pdpImages[v.Code]}
                                                            alt={v.icon || v.Icon}
                                                        />
                                                    )}
                                                    {
                                                        isDiamondShape && (
                                                            <img
                                                                className={classes.swatchImg}
                                                                src={isDiamondShape.imgPath}
                                                                alt={v.icon || v.Icon}
                                                            />
                                                        )
                                                    }
                                                    {PDPColorImage[v.Code?.split('_')[1]] && (
                                                        <img
                                                            className={`${classes.swatchImg} ${classes.swatchImgColor}`}
                                                            src={PDPColorImage[v.Code?.split('_')[1]]}
                                                            alt={v.Name}
                                                        />
                                                    )}
                                                    <span className={classes.swatchText}>{v.Name}</span>
                                                </Button> : null}</Fragment>
                                            )
                                        })}
                                    </div>
                                </Label>
                                {
                                    fieldValidation.length > 0 &&
                                        fieldValidation.includes(key)?
                                        (<span className={classes.error}><Icon name={'hk_info_circle'} />{`Please select your ${key.replace('_', ' ')}.`}</span>)
                                        : null
                                }
                            </div>: null}</Fragment>
                        )
                    }
                })}
                <div className={`${classes.items}`}>
                    <Label text={`Engraving`} optionsText={<em>(Complimentary)</em>}>
                        <FloatingLabel
                            label={"Your Message"}
                            labelId={`Engraving`}
                            maxLength={18}
                            keyName={"Engraving"}
                            setRingOptions={setRingOptions}
                            value={ring.options["Engraving"]}
                        />
                    </Label>
                </div>
                {(ring.extraData.Bands !== 0) ? <><div className={`${classes.items} band_options`}>
                    <Label text={"Matching Band"} optionsText={<span className={classes.smallChar}>(s)</span>} page={'pdpBuilder'}>
                        <div className={classes.wrapper}>
                            {weddingBand.map((band,index)=>{
                                return(
                                    <Button type={'button'} code={String(index)} key={index} buttonType={ring.options["Wedding_Band"] === band ? "active" : undefined} className={classes.button} onClick={()=>handleWeddingBand(index,band)}>
                                        <span className={classes.swatchText}>{band}</span>
                                    </Button>
                                )
                            })}
                        </div>
                    </Label>
                </div>
                {Array.from(Array(ring.tabIndex)).map((bandIndex,i) => {
                    return (
                        <React.Fragment key={i}>
                            <div className={`${classes.items} ${i === 0 ? 'left_band_color':'right_band_color'} hk_metal_color`}>
                                <Label text={`${i === 0 ? 'First':'Second'} Band Metal`} page={'pdpBuilder'}>
                                    <div className={classes.wrapper}>
                                        {weddingButton.map((band, index) => {
                                          return(
                                            <Fragment key={index}>{!(ring.options.Metal !== "PT" ? !((ring.options[i === 0 ? `${ring.options["Wedding_Band"]}_First_Metal_Band` : `${ring.options["Wedding_Band"]}_Second_Metal_Band`])?.match(band.Code.split('_')[0])) : !["18_YG","18_RG","PT_WG"].includes(band.Code)) ? <Button
                                              type={'button'}
                                              key={index}
                                              buttonType={ring.options[i === 0 ? `${ring.options["Wedding_Band"]}_First_Metal_Band` : `${ring.options["Wedding_Band"]}_Second_Metal_Band`] === band.Code ? "active" : undefined}
                                              className={`${classes.button} hk_metal_color_button`}
                                              onClick={() => handleMatchingBand(i, band.Code)}
                                            >
                                              <img className={`${classes.swatchImg} ${classes.swatchImgColor}`}
                                                   src={PDPColorImage[band.Code.split("_")[1]]} alt={band.Name} />
                                              <span className={classes.swatchText}>{band.Name}</span>
                                            </Button> : null}</Fragment>
                                            )
                                        })}
                                    </div>
                                </Label>
                            </div>
                            <div className={`${classes.items}`}>
                                <Label text={`${i === 0 ? "First" : 'Second'} Band Engraving`} optionsText={<em>(Complimentary)</em>} page={'pdpBuilder'}>
                                    <FloatingLabel
                                        label={'Your Message'}
                                        labelId={`${ring.tabIndex}_${i}_Engraving`}
                                        maxLength={18}
                                        keyName={`${i === 0 ? "First" : 'Second'}_Band_Engraving`}
                                        setRingOptions={setRingOptions}
                                        value={ring.options[`${i === 0 ? "First" : 'Second'}_Band_Engraving`]}
                                    />
                                </Label>
                            </div>
                        </React.Fragment>
                    )
                })}
              </> : null}
            </div>
            <div className={classes.buttonWrap}>
                <Button type={"button"}
                        buttonType={"btnPrimary"}
                        className={classes.primaryButton}
                        onClick={handleSelectSetting}
                >
                    {/*<Icon name={"hk-viewDiamond"} />*/}
                    <span>Select This Setting</span>
                </Button>
                {Object.keys(diamond.details).length > 0 && !(rpid && uuid) && builder_mode !== "edit"  ? <Button type={'button'} className={classes.backButton}
                        onClick={async () => {
                            // navigate(styleId?.includes("RP") ? -2 : -1);
                            setBackToSelectDiamondPage(true);
                            (screenManager as any).changeScreen({
                                viewName: ring.journeyStartedFrom,
                                styleId:null,
                                id: ring.journeyStartedFrom === 'details'?(diamond.details.id):null,
                            })
                            // window.history.go(styleId?.includes("RP") ? -2 : -(ring.afterDiamondPageCount))
                            await setBackDiamondDetails(true);
                            await setHeaderTabDefault();
                            await setInitialOption();
                            await setIsShowFooter(false);
                            await toggleScrollTop(true);
                        }}
                >
                <div className={classes.navigate}>
                    {/*<Icon name={"hk_chevron_left"} />*/}
                    <span>Back to Diamond Selection</span>
                </div>
            </Button> : null}
                {Object.keys(settingFilter.options).length > 0 && !(rpid && uuid) && builder_mode !== "edit" && !footer.isRedirectToEdit ?
                    (<Button type="button" className={classes.backButton} onClick={() => {
                        // window.history.go(-1);
                        (screenManager as any).changeScreen({
                            viewName:'setting',
                            styleId:null,
                            id: diamond.details.id ? diamond.details.id:null,
                        })
                        setInitialOption();
                        if(!(Object.keys(diamond.details).length > 0)){
                            setIsShowFooter(false);
                        }
                    }}>
                        <span className={classes.navigate}>Back to Setting Selection</span>
                    </Button>) : null
                }
            </div>
        </div>
    </>
  )
}

export default connector(PdpBuilder);
